import { OrderLineDetails } from '@dx-ui/lib-oasis-ubl-2.1/src/OrderModel';
import _ from 'lodash';
import { InvoiceLine, Line, ValueString } from '../shared/types';
import { round } from '../shared/webForms/utils';
import { LineProcessor, TaxAndPriceUtils } from './FormDataHelpers';

export class FormDataLineNoteHelpers {
  public static readonly NotePrefix_TotalWithVat = 'TaxInclusiveAmount:';

  /**
   * from V2 and for invoice : totalWithVat for lines is stored in one of the line notes in the format "TaxInclusiveAmount:XXX"
   */
  public static getLineNoteTotalWithVat(line: InvoiceLine | OrderLineDetails) {
    if (LineProcessor.isLineForOrder(line)) {
      const totalWithoutVat = (line as OrderLineDetails).LineItem?.[0]
        ?.TaxTotal?.[0]?.TaxSubtotal?.[0]?.TaxableAmount?.[0]?._;
      const totalVat = (line as OrderLineDetails).LineItem?.[0]?.TaxTotal?.[0]
        ?.TaxAmount?.[0]?._;
      const totalWithVat =
        totalVat !== undefined && totalWithoutVat !== undefined
          ? TaxAndPriceUtils.limitDecimals(totalVat + totalWithoutVat)
          : undefined;

      return totalWithVat;
    } else {
      const lineNote = (line as InvoiceLine).note?.find((x) =>
        x?.value?.startsWith(FormDataLineNoteHelpers.NotePrefix_TotalWithVat)
      );
      if (lineNote && lineNote.value) {
        const note = lineNote.value;
        const strVal = note.replace(
          FormDataLineNoteHelpers.NotePrefix_TotalWithVat,
          ''
        );
        let val = parseFloat(strVal);
        return val;
      }
    }
  }

  /**
   * from V2 and for invoice: totalWithVat for lines is stored in one of the line notes in the format "TaxInclusiveAmount:XXX"
   * Convention: element 0 - line note, element 1 - total with VAT
   */
  public static setLineNoteTotalWithVat(
    line: Line | OrderLineDetails,
    value: number | undefined
  ) {
    if (LineProcessor.isLineForOrder(line)) {
      return;
    } else {
      // always ensure there is an element
      // first element reserved for the line note
      if (!(line as Line)?.note?.[0]?.value) {
        (line as Line).note = [{ value: '' }];
      }

      // removes the total with vat line
      _.remove((line as Line).note || [{ value: '' }], (note) =>
        note.value?.startsWith(FormDataLineNoteHelpers.NotePrefix_TotalWithVat)
      );

      // get element at index 0 - note value
      let lineNote: ValueString | undefined;
      if ((line as Line).note?.length) {
        lineNote = _.head(
          _.pullAt((line as Line).note || [{ value: '' }], [0])
        );
      }

      //rebuild the list in the proper order
      const other = (line as Line).note;
      (line as Line).note = [];

      // position 0: line note
      (line as Line).note?.push(lineNote ?? { value: '' });

      // position 1: total with vat
      if (value !== undefined) {
        const roundedValue = round(value);
        (line as Line).note?.push({
          value: `${FormDataLineNoteHelpers.NotePrefix_TotalWithVat}${roundedValue}`,
        });
      }

      //other notes (?) - not sure this is needed
      if (other) {
        (line as Line).note?.push(...other);
      }
    }
  }
}
