import { ArrayInput } from '@dx-ui/dx-common';
import { Chip, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useTranslate } from 'react-admin';
import { DespatchAdviceService } from '../../../services';
import { DocumentServiceName, TemplateModelElement } from '../../types';
import { useCommonFieldStyles } from './CommonFieldStyles';
import { FieldComponentProps } from './FieldComponentProps';
import { FormField } from './FormField';
import CustomSimpleFormIterator from './OrderSimpleFormIterator';

const useStyles = makeStyles(
  (theme) => ({
    linesMaxWidth: {
      overflowX: 'auto',
      overflowY: 'hidden',
    },
  }),
  { name: 'ArrayLinesTable' }
);

const renderItems = (items, otherProps) =>
  items?.map((item, idx) => {
    return (
      <FormField
        {...otherProps}
        key={`${item.name}_${idx}`}
        source={item.source} // need to set it here
        field={item}
      />
    );
  });

const createNewLine = (field: TemplateModelElement) => {
  if (field.options === undefined) return;

  if (field.options.noDefault === true) {
    return () => {};
  }

  if (field.options.documentServiceName === undefined) {
    return () => {};
  }

  if (
    field.options.documentServiceName ===
    DocumentServiceName.DESPATCH_ADVICE_SERVICE
  ) {
    return DespatchAdviceService.initNewLine(field.items);
  } else {
    return () => {};
  }
};
/**
 * Renders an Order lines table for Desapatch Advices.
 */
const ArrayLinesTable = (props: FieldComponentProps) => {
  const { field, readOnly: readOnlyForm, basePath } = props;

  const translate = useTranslate();
  const classes = useStyles(props);
  const commonFieldClasses = useCommonFieldStyles(props);

  const options = field.options;
  const readOnly = !!readOnlyForm || !!options?.readOnly;

  const sanitizeArrayInputProps = ({
    selectValues,
    template,
    classes,
    ...rest
  }: any) => rest;

  const sanitizeItemProps = ({ field, classes, ...rest }: any) => rest;

  return (
    <Grid container item direction='column' spacing={2}>
      {
        <Grid item>
          <Typography className={commonFieldClasses.header} variant='h6'>
            {options?.title &&
              field.title &&
              translate(field.title, { _: field.title })}
            {field.businessTerms?.map((s) => {
              return (
                <Chip
                  label={s}
                  size='small'
                  style={{ marginLeft: '0.5em', fontSize: '0.5rem' }}
                  key={s}
                />
              );
            })}
          </Typography>
        </Grid>
      }
      <Grid item>
        <Paper square className={commonFieldClasses.paper}>
          <Grid container direction='column'>
            {field.items && (
              <Grid item className={classNames(classes.linesMaxWidth)}>
                <ArrayInput {...sanitizeArrayInputProps(props)} label={''}>
                  <CustomSimpleFormIterator
                    disableAdd={readOnly || !(options?.addable ?? true)}
                    disableRemove={readOnly || !(options?.removable ?? true)}
                    noComputation={options?.noComputationAddRemove ?? true}
                    createNewLine={createNewLine(field)}
                  >
                    {renderItems(field.items, {
                      ...{ ...sanitizeItemProps(props), basePath },
                    })}
                  </CustomSimpleFormIterator>
                </ArrayInput>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ArrayLinesTable;
