import {
  AuditTrailTimeline,
  Constants,
  DocumentTypeCode,
  EmptyValueField,
  GA_EVENTS,
  Metadata,
  MultiSelectInput,
  ProcessStatus,
  ProcessStatusIcon,
  ReadStatusIcon,
  cellStyle,
  sendGAEvent,
  widthPresets,
} from '@dx-ui/dx-common';
import { Drawer, IconButton, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import moment from 'moment';
import React, { useState } from 'react';
import { useGetIdentity, useTranslate } from 'react-admin';
import { AlfrescoDocumentService, DataHelpers } from '../services';

const useStyles: any = makeStyles(
  (theme) => ({
    header: {
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      minHeight: 60,
    },
    drawerCloseButton: {
      color: 'white',
      '&:hover': {
        backgroundColor: 'rgb(255,255,255,0.2)',
      },
      margin: '0 5px',
    },
    drawerOpenButton: {
      padding: 0,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:after': {
        backgroundColor: 'transparent !important',
      },
    },
    drawerTitle: {
      color: 'white',
    },
  }),
  { name: 'ProcessDetailsFields' }
);

export const ReadStatusField = ({ record = { properties: [] } }) => {
  const translate = useTranslate();
  const readStatus = record.properties[Metadata.readStatus];
  if (readStatus === Constants.READ_STATUS_NEW) {
    return <Typography>{translate('dxMessages.readStatus.NEW')}</Typography>;
  } else if (readStatus === Constants.READ_STATUS_DOWNLOADED) {
    return (
      <Typography>{translate('dxMessages.readStatus.DOWNLOADED')}</Typography>
    );
  } else if (readStatus === Constants.READ_STATUS_READ) {
    return <Typography>{translate('dxMessages.readStatus.READ')}</Typography>;
  } else if (readStatus === Constants.READ_STATUS_CONVERTED) {
    return (
      <Typography>{translate('dxMessages.readStatus.CONVERTED')}</Typography>
    );
  } else {
    return readStatus ? (
      <Typography>{readStatus}</Typography>
    ) : (
      <EmptyValueField />
    );
  }
};

export const ReadStatusIconized = ({
  className,
  record = { properties: [] },
}) =>
  record.properties[Metadata.readStatus] ? (
    <Typography className={className}>
      {record.properties[Metadata.readStatus] === Constants.READ_STATUS_NEW && (
        <ReadStatusIcon />
      )}
    </Typography>
  ) : (
    <span />
  );

/**
 * Renders the ProcessStatus as a Field
 * When displayed in a List (readOnly false), renders as a button to open Audit trail drawer
 * @param {*} record current row values
 * @param {string} tz timezone
 * @param {boolean | undefined} readOnly
 */
export const ProcessStatusField = React.forwardRef<any, any>(
  ({ tz, record = { properties: [] }, readOnly, ref }: any) => {
    const translate = useTranslate();
    const processStatus = record.properties[Metadata.processStatus];
    const documentId = record.properties[Metadata.documentId];
    const isWebDocument: boolean =
      AlfrescoDocumentService.isWebDocument(record);
    const isAperak: boolean =
      DataHelpers.getDocumentTypeCode(record) === DocumentTypeCode.APERAK;

    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const { identity } = useGetIdentity();
    // @ts-ignore
    const account: Account = identity;

    const handleClick = () => {
      sendGAEvent(
        GA_EVENTS.categories.AUDIT_TRAIL.name,
        GA_EVENTS.categories.AUDIT_TRAIL.actions.SHOW,
        account?.company?.cmsRootDir,
        documentId
      );
      setOpen(true);
    };

    const handleCloseClick = () => {
      setOpen(false);
    };

    if (processStatus) {
      if (readOnly || isWebDocument || isAperak) {
        return (
          <div>
            <Typography>
              {<ProcessStatusIcon status={processStatus} />}
              <span>
                {translate(`dxMessages.processStatus.${processStatus}`)}
              </span>
            </Typography>
            <Typography variant='caption'>
              {record.modifiedAt ? (
                moment(record.modifiedAt).tz(tz).format('HH:mm - DD MMM YYYY')
              ) : (
                <EmptyValueField />
              )}
            </Typography>
          </div>
        );
      } else {
        return (
          <>
            <Tooltip
              title={translate(
                'dxMessages.processStatus.openAuditTrail.tooltip'
              )}
            >
              <IconButton
                color='primary'
                onClick={handleClick}
                ref={ref}
                disableRipple
                classes={{ root: classes.drawerOpenButton }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography>
                      {<ProcessStatusIcon status={processStatus} />}
                    </Typography>
                    <Typography color='textPrimary'>
                      {translate(`dxMessages.processStatus.${processStatus}`)}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Typography variant='caption' color='textPrimary'>
                      {record.modifiedAt ? (
                        moment(record.modifiedAt)
                          .tz(tz)
                          .format('HH:mm - DD MMM YYYY')
                      ) : (
                        <EmptyValueField />
                      )}
                    </Typography>
                  </div>
                </div>
              </IconButton>
            </Tooltip>
            <Drawer anchor='right' open={open} onClose={handleCloseClick}>
              <div className={classes.header}>
                <IconButton
                  classes={{ root: classes.drawerCloseButton }}
                  onClick={handleCloseClick}
                >
                  <IconKeyboardArrowRight />
                </IconButton>
                <Typography
                  variant='h6'
                  classes={{ root: classes.drawerTitle }}
                >
                  {translate('dxMessages.audit_trail.show')}
                </Typography>
              </div>
              <AuditTrailTimeline documentId={record.id} />
            </Drawer>
          </>
        );
      }
    } else {
      return <EmptyValueField />;
    }
  }
);

export const createColumnsProcessDetails = () => [
  {
    id: Metadata.readStatus,
    label: 'dxMessages.headers.readStatus',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    renderCell: (props) => <ReadStatusField {...props} />,
  },
];

export const createProcessDetailsFilters = () => [
  {
    id: Metadata.readStatus,
    filter: (props) => (
      <MultiSelectInput
        label='dxMessages.headers.readStatus'
        source={Metadata.readStatus}
        width={widthPresets.small}
        sortLabels={true}
        {...props}
        options={[
          {
            id: Constants.READ_STATUS_NEW,
            label: 'dxMessages.readStatus.NEW',
          },
          {
            id: Constants.READ_STATUS_DOWNLOADED,
            label: 'dxMessages.readStatus.DOWNLOADED',
          },
          {
            id: Constants.READ_STATUS_READ,
            label: 'dxMessages.readStatus.READ',
          },
          {
            id: Constants.READ_STATUS_CONVERTED,
            label: 'dxMessages.readStatus.CONVERTED',
          },
        ]}
      />
    ),
  },
  {
    id: Metadata.processStatus,
    filter: (props) => (
      <MultiSelectInput
        label='dxMessages.headers.status'
        source={Metadata.processStatus}
        width={widthPresets.large}
        sortLabels={true}
        {...props}
        options={[
          {
            id: ProcessStatus.ACCEPTED_BY_RECIPIENT,
            label: 'dxMessages.processStatus.ACCEPTED_BY_RECIPIENT',
          },
          {
            id: ProcessStatus.CONTENT_ERROR,
            label: 'dxMessages.processStatus.CONTENT_ERROR',
          },
          {
            id: ProcessStatus.FORMAT_ERROR,
            label: 'dxMessages.processStatus.FORMAT_ERROR',
          },
          {
            id: ProcessStatus.NO_CREDIT,
            label: 'dxMessages.processStatus.NO_CREDIT',
          },
          {
            id: ProcessStatus.PENDING_ENRICHMENTS,
            label: 'dxMessages.processStatus.PENDING_ENRICHMENTS',
          },
          {
            id: ProcessStatus.PENDING_CORRECTION,
            label: 'dxMessages.processStatus.PENDING_CORRECTION',
          },
          {
            id: ProcessStatus.RECEIVED_BY_DX,
            label: 'dxMessages.processStatus.RECEIVED_BY_DX',
          },
          {
            id: ProcessStatus.PAID_BY_RECIPIENT,
            label: 'dxMessages.processStatus.PAID_BY_RECIPIENT',
          },
          {
            id: ProcessStatus.PARTIALLY_PAID_BY_RECIPIENT,
            label: 'dxMessages.processStatus.PARTIALLY_PAID_BY_RECIPIENT',
          },
          {
            id: ProcessStatus.RECEIVED_BY_RECIPIENT,
            label: 'dxMessages.processStatus.RECEIVED_BY_RECIPIENT',
          },
          {
            id: ProcessStatus.REJECTED_BY_RECIPIENT,
            label: 'dxMessages.processStatus.REJECTED_BY_RECIPIENT',
          },
          {
            id: ProcessStatus.RECEIVED_BY_BSP,
            label: 'dxMessages.processStatus.RECEIVED_BY_BSP',
          },
          {
            id: ProcessStatus.CONDITIONALLY_ACCEPTED,
            label: 'dxMessages.processStatus.CONDITIONALLY_ACCEPTED',
          },
          {
            id: ProcessStatus.RECEIVED_AND_VALIDATED_BY_BSP,
            label: 'dxMessages.processStatus.RECEIVED_AND_VALIDATED_BY_BSP',
          },
          {
            id: ProcessStatus.REJECTED_BY_BSP,
            label: 'dxMessages.processStatus.REJECTED_BY_BSP',
          },
          {
            id: ProcessStatus.SENT,
            label: 'dxMessages.processStatus.SENT',
          },
          {
            id: ProcessStatus.UNDER_QUERY,
            label: 'dxMessages.processStatus.UNDER_QUERY',
          },
          {
            id: ProcessStatus.IN_PROCESS,
            label: 'dxMessages.processStatus.IN_PROCESS',
          },
          {
            id: ProcessStatus.WAITING_FOR_RECIPIENT,
            label: 'dxMessages.processStatus.WAITING_FOR_RECIPIENT',
          },
          {
            id: ProcessStatus.MATCHING_IN_PROGRESS,
            label: 'dxMessages.processStatus.MATCHING_IN_PROGRESS',
          },
          {
            id: ProcessStatus.MATCHING_ERROR,
            label: 'dxMessages.processStatus.MATCHING_ERROR',
          },
          {
            id: ProcessStatus.ALLOCATION_IN_PROGRESS,
            label: 'dxMessages.processStatus.ALLOCATION_IN_PROGRESS',
          },
          {
            id: ProcessStatus.REJECTED_BY_SUPPLIER,
            label: 'dxMessages.processStatus.REJECTED_BY_SUPPLIER',
          },
        ]}
      />
    ),
  },
];
